

body {
  margin: auto !important;
  font-family: -apple-system, BlinkMacSystemFont, "Lato", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 650px;
  background-color: white;
}

footer {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

main,
.ant-layout {
  background-color: white !important;
}

.ant-calendar-selected-day > div:nth-child(1) {
  background-color: #ffcd45 !important;
}

.ant-calendar-today > div:nth-child(1) {
  border-color: #ffcd45;
  color: #202020;
}

.ant-calendar-next-year-btn,
.ant-calendar-prev-year-btn {
  display: none !important;
}

.ant-calendar-prev-month-btn {
  left: 19px !important;
}
.ant-calendar-next-month-btn {
  right: 19px !important;
}

.ant-calendar-today.ant-calendar-disabled-cell > div:nth-child(1) {
  border-width: 0px;
}
