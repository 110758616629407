.ant-slider {
    /* margin-top: 20px;
    margin-bottom: 20px; */
    margin: 20px 15px 20px 15px;
}

.ant-slider-track {
    margin-top: 6px !important;
    background-color: black !important;
}

.ant-slider-step {
    margin-top: 6px !important;
}
.ant-slider-rail {
    margin-top: 6px !important;
}

.slider-handle-color-focus-shadow {
    border-color: black !important;
    background-color: black !important;
    color: black !important;
    --antd-wave-shadow-color: black !important;
}

.ant-slider-handle {
    border-color: black !important;
    background-color: black !important;
    width: 25px !important;
    height: 25px !important;
    color: black !important;
    box-shadow: none !important;

    --antd-wave-shadow-color: black !important;
}

.ant-slider-handle:focus {
    border-color: black !important;
    background-color: black !important;

    --antd-wave-shadow-color: black !important;
}
.ant-slider-handle-dragging {
    border-color: black !important;
    background-color: black !important;
    --antd-wave-shadow-color: black !important;
    outline: none;
}

.ant-slider-handle-click-focused:hover {
    background-color: black !important;
    border: black !important;
    --antd-wave-shadow-color: black !important;
}