@import '../../../../sass/variables.scss';

.campingRating {
    .ant-rate-star-full {
        path {
            fill: $primary;
        }
    }

    .ant-rate-star-zero {
        path {
            fill: $white;
            stroke: $primary;
        }
    }
}