@import "../../sass/variables.scss";
.wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logoHomeStyle {
  z-index: 2;
  & > path {
    fill: black;
  }
}