@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

//@import "../node_modules/antd/dist/antd.css";
@import "./sass/variables.scss";
//

*,
input {
  font-family: "Lato";
  font-weight: 400;
  // color: $fontColorSoftBlack;
  // font-size: 1rem!important;
  letter-spacing: normal;
}

// .ant-picker-header-super-prev-btn,
// .ant-picker-header-super-next-btn {
//   display: none !important;
// }

li.slick-active {
  button {
    opacity: 1 !important;
    background-color: $primary !important;
  }
}

@-webkit-keyframes left-to-right {
  from {
    left: -200%;
  }
  to {
    left: 0;
  }
}

@keyframes left-to-right {
  from {
    left: -200%;
  }
  to {
    left: 0;
  }
}

html,
body {
  width: 100%;
  height: 100%;

  section {
    position: relative;
  }

  .ant-message-notice-content {
    text-align: left;
    width: 80%;
    max-width: 400px;
  }
  .ant-btn-primary,
  .ant-btn-primary:focus {
    background-color: $fontColorSoftBlack;
    color: $white;
    border-color: $fontColorSoftBlack;
  }
  .ant-btn-primary:hover {
    background-color: rgba($fontColorSoftBlack, 0.8);
    border-color: $fontColorSoftBlack;
    color: $white;
  }

  .ant-modal-content {
    border-radius: 15px;
  }
  .ant-modal-confirm-btns {
    & > button {
      background-color: $primary !important;
      border-radius: 28px;
      border: 0;
      color: $fontColorSoftBlack !important;
      font-weight: 700;
    }

    & > button:disabled {
      color: $disabledColor !important;
      background-color: $disabledButtonColor !important;
    }
  }

  .ant-modal-body {
    border-radius: 6px;
  }
}

body {
  &.withOverflowHidden {
    overflow: hidden;
    overscroll-behavior: contain;
  }
  &.withOverflowHiddenCheckin {
    // overflow: hidden;
    overscroll-behavior: contain;
  }
}

#root {
  height: 100%;

  & > div {
    height: 100%;
  }
}

.ant-checkbox-inner {
  border: 1px solid $quickSilver !important;
  color: $fontColorSoftBlack;
  padding: 7px;
}
.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-inner::after {
  background-color: $primary !important;
  border-color: $white !important;
}

.ant-checkbox-checked::after {
  border-color: $primary !important;
}

@media (min-width: $bodyWidth) {
  html,
  body {
    height: fit-content;
    max-width: $bodyWidth;
    margin: 0 auto;
    padding: 5px 0px;
    background-color: $tertiary;
    border-radius: 12px 12px 0 0;
  }

  section {
    border-radius: 12px 12px 12px 12px;
    box-shadow: 0px 1px 10px 3px $silver;
  }

  
}

html, body.bookings-register {
  max-width: none !important; 
}

html, header, body.bookings-register {
  max-width: none !important; 
}


.ant-spin-dot-item {
  background-color: $primary;
}

.ant-drawer-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
}

.ant-drawer-content {
  border-radius: 25px 25px 0px 0px;
}

.ant-drawer-body {
  padding: 0px;
}

.ant-popover-title {
  min-width: 0px !important;
}

.ant-modal-confirm-title {
  font-weight: 600;
}

.datepicker {
  position: inherit !important;
  padding-top: 0px;
  background-color: #fff !important;

  * {
    cursor: n-resize !important;
  }
  & > .datepicker-content {
    padding-top: 0px !important;
    border-radius: 12px;
    border: 1px solid rgb(227, 227, 227);
    box-shadow: none;
  }
  .datepicker-viewport {
    height: 150px;
  }

  .datepicker-wheel {
    border-top: 1px solid $silver !important;
    border-bottom: 1px solid $silver !important;
  }
}

#CookiebotWidget {
  display: none !important;
}

.ant-image-preview-operations-operation:not(:first-child) {
  display: none;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: unset;
}
.grecaptcha-badge {
  display: none;
}

.slick-dots li button {
  position: relative !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.slick-dots li button:before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-48%, -48%);
}


.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: $primary !important;
}
.ant-steps-item-process .ant-steps-item-icon {
  border-color: $primary;
}

.ant-steps-item-wait .ant-steps-item-icon {
  border-color: black;
}
.ant-steps-item-icon {
  background-color: #fff !important;
  color: black;
  
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon{
  color: black
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: black;
}

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: black;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: black;
  border-color: black;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: black;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: $primary;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: black;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: $primary
}


.ant-form-item-label > label {
  font-weight: bold;
}

.ant-select-selector {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-select-selector:hover {
  border-color: $primary !important;
} 

.ant-row {
  padding: 0px 5px 0px 5px
}
.ant-select {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.ant-input-group {
  height: 100% !important;

  input {
    height: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  input:hover {
    border-color: $primary;
  }

  .ant-input-group-addon .ant-select {
    margin: 0px
  }

  .ant-input-group-addon {
    padding: 0%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}