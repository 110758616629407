//Schemes

$white: #ffffff;
$black: #000000;
$platinum: #ebe9e5;
$platinum-2: #ebebeb;
$platinum-3: #e6e6e6;
$silver: #bfbfbf;
$quickSilver:#555;
$eerieBlack: #202020;
$sonicSilver: #707070;
$black-29: rgba(black, 0.29);
$black-19: rgba(black, 0.19);
$black-17: rgba(black, 0.17);
$cyberYellow: #ffd100;
$spanishGrey: #909090;
$jet: #323232;
$cultured: #eeeeee;
$fuzzyWuzzy: #d86161;
$graniteGrey: #606060;
$mediumCarmine: #b53b34;
$timberwolf: #D7D2CB;
$onyx: #404040;

// /*Colors*/
$primary: $cyberYellow;
$secondary: $cultured;
$tertiary: $platinum;
$tertiaryDark: #d9d9d9;

$fontColorStrongBlack: #303030;
$fontColorSoftBlack: $eerieBlack;
$greenColor: #3cb878;
$redColor: $fuzzyWuzzy;
$dangerColor: $fuzzyWuzzy;
$disabledColor: $cultured;
$disabledButtonColor: #dcd8d2;
$focusedButtonColor: #ffe360;

$marginLevelOne: 4px;
$marginLevelTwo: 8px;
$marginLevelThree: 12px;
$marginLevelFour: 16px;
$borderRadiusLevelOne: 15px;

// Valor de referencia
$cardHeightMobile: 140px;
// 10px menos que el anterior.
$roomCardWidthMobile: 160px;
// el doble del anterior
$roomCardHeightMobile: 260px;

$bodyWidth: 650px;
$maxWidth: 535px;
$homeHeaderHeight: 29vh;

$floatingBarHeight: 9em;
